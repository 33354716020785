.logoForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1%;
}

@media screen and (max-width: 1000px) {
  .logoForm {
    display: none;
  }
}

#UMRLogo {
  width: 424px;  
  height: 192px;
}

#header {
  background-color: #23396e;
}