.logoForm {
    display: flex; /* Verwendet Flexbox, um die Bilder horizontal auszurichten */
    justify-content: center; /* Zentriert die Bilder horizontal */
    align-items: center; /* Zentriert die Bilder vertikal */
  }
  
  #IGDDLogo, #UMRLogo, #ZfLbLogo, #URLogo {
    width: 150px; /* Passt die Breite der Bilder an */
    height: auto; /* Passt die Höhe der Bilder proportional an */
    margin: 2px; /* Fügt zwischen den Bildern Abstand hinzu */
  }
  #DSALogo {
    width: 80px; /* Passt die Breite der Bilder an */
    height: auto; /* Passt die Höhe der Bilder proportional an */
    margin: 2px; /* Fügt zwischen den Bildern Abstand hinzu */
  }
  #GGSGLogo {
    width: 120px; /* Passt die Breite der Bilder an */
    height: auto; /* Passt die Höhe der Bilder proportional an */
    margin: 2px; /* Fügt zwischen den Bildern Abstand hinzu */
  }

  #dsaLogo-long {
    max-height: 68px;
  }